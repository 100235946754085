//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from '@/service/miceService'
  export default {
    props: ['itemtype'],
    data() {
      return {
        city: {
          txt:'我的城市'
        },
        hospitail:"我的医院",
        lookHospitail: '',
        lookCity: '',
        lookNum: 1,
        hospitalList: [{name:"全部"}],
        cityList: [{txt:"全部"}],
      }
    },
    methods: {
      lookFilterList(type) {//展示我的城市/我的医院对应下拉列表
        this.lookCity = false
        this.lookHospitail = false
        if(this.lookNum==1){
          switch (type) {
            case 'city':
              this.lookCity = true
              this.lookHospitail = false
              this.cityList = [{txt:"全部"}]
              this.hospitail = "我的医院"
              this.getCity()
              break;
            case 'hospitail':
              this.lookCity = false
              this.lookHospitail = true
              this.hospitalList = [{name:"全部"}]
              this.getHospital()
              break;
          }
          this.lookNum=0
        }else if(this.lookNum==0){
          switch (type) {
            case 'city':
              this.lookCity = false
              break;
            case 'hospitail':
              this.lookHospitail = false
              break;
          }
          this.lookNum=1
        }
        
      },
      getCity: async function() {//获取我的城市列表

        let params = {
          itemType:this.itemtype
        }
        const res = await miceService.queryMyCity(params)
        if(res.success&&res.content.data.length>0){
          this.mergeArray(this.cityList,res.content.data,'ct')
        }
      },
      getHospital: async function() {//获取我的医院列表
        let params = {
          itemType:this.itemtype,
          cityId: this.city.pingRegionId
        }
        const res2 = await miceService.queryMyHospitals(params)
        let res = res2.content
        if(res.status&&res.data.length>0){
          this.mergeArray(this.hospitalList,res.data,'hp')
        }
      },
      closeFilter(item,type) {//选择对应城市/医院存储在session，以及Bus传递
        if(type=="city"){
          this.city = item
          this.lookCity = false
          let paramsCityId = {
            cityId:  item.pingRegionId
          }
//        sessionStorage.removeItem('cityId')
//        sessionStorage.removeItem('city')
          this.Bus.$emit("paramsCityId", paramsCityId)
        }else{
          this.hospitail = item.name
          this.lookHospitail = false
          let paramsHpId = {
            hospitalId:  item.rowId
          }
//        sessionStorage.removeItem('hospitalId')
//        sessionStorage.removeItem('hospital')
          this.Bus.$emit("paramsHpId", paramsHpId)
        }
        this.lookNum=1
      },
      mergeArray:function (arr1, arr2,type){ //mergeArray 数组去重合并
        for (var i = 0 ; i < arr1.length ; i ++ ){
          for(var j = 0 ; j < arr2.length ; j ++ ){
            if (type=="hp"&&arr1[i].recommendId === arr2[j].recommendId){
              arr1.splice(i,1);
            }else if(type=="ct"&&arr1[i].pingRegionId === arr2[j].pingRegionId) {
              arr1.splice(i,1);
            }
          }
        }
        for(var i = 0; i <arr2.length; i++){
          arr1.push(arr2[i]);
        }
        return arr1;
      }
    },
  }
