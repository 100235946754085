//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import NormalHead from '@/views/components/ishop/header.vue'
	import DropdownFilter from '@/views/components/ishop/dropdownFilter.vue'
	import shopDetail from '@/views/components/ishop/dialogs/shopDetail'
	import customShop from './step2CustomShop.vue';//手工录入
	import { miceService } from '@/service/miceService'
	export default {
		name: 'myShops',
		data: function() {
			return {
				Iftrue: false,
				Ifshow: false,
				isEmptyShopData: true,
				shopData: [],
				headValue: {
					name: "我的推荐餐厅",
					haveBack: true,
				},
				hosipotail: [],
				params: {
					itemType: 5,
					hospitalId: sessionStorage.getItem("hospitalId"),
					cityId: sessionStorage.getItem("pingRegionId"),
					channel: sessionStorage.getItem('channel') || 'h5'
				},
				cart: { //选择的商户信息
					true: true,
					select: 0,
					sum: this.$store.state.ishop.maxQty5,
					itemType: 5,
					shops: []
				},
			}
		},
		components: {
			NormalHead,
			DropdownFilter,
			shopDetail,
			customShop, //手工录入自定义餐厅页
		},
		created() {
			this.queryMyShops()
		},
		mounted() {
			this.Bus.$on('paramsHpId', params => {
				this.params.hospitalId = params.hospitalId
				this.queryMyShops()
			});
			this.Bus.$on('paramsCityId', params => {
				this.params.cityId = params.cityId
				this.params.hospitalId = ""
				this.queryMyShops()
			});
		},
		methods: {
			addShop() {
				//选择
				if(this.$store.state.ishop.allowRecommendType1) {
					this.$router.push({
						path: '/step1SelectShop'
					});
				}
				if(!this.$store.state.ishop.allowRecommendType1 && this.$store.state.ishop.allowRecommendType2){
					this.$store.commit('changeisDisable', true)
					this.Bus.$emit('openPropu', true);
				}
			},
			go(type) {
				var dstRoutPath = "";
				switch(type) {
					case 0:
						dstRoutPath = "/step1SelectShop";
						break;
					case 1:
						dstRoutPath = "/step1SelectShop";
						break;
					case 2:
						dstRoutPath = "/step2CustomShop";
						break;
				}
				this.$router.push(dstRoutPath);
			},
			queryMyShops: async function() {
				console.log('miceService.queryMyShops');
				let params = this.params
				const res = await miceService.queryMyShops(params)
				if(res.success) {
					this.isEmptyShopData = res.content.data.customed.length <= 0 && res.content.data.selected.length <= 0;
					this.shopData = res.content.data;
				}
			},
			removeMyShop: async function(shop, index, type) {
				let input = {
					recommendId: shop.recommendId
				}
				const res = await miceService.removeMyShop(input)
				if(res.status) {
					switch(type) {
						case 'shop':
							this.shopData.customed.splice(index, 1)
							break;
						case 'item':
							this.shopData.selected.splice(index, 1)
							break;
					}

				}
			},
			changeMyshop(shop, index, type) {
				if(type == "item") shop.checked = !shop.checked
				this.messageBox({
					message: `确认删除该推荐餐厅`,
					closeOnClickModal: false,
					showCancelButton: true,
					cancelButtonText: "否",
					confirmButtonText: '是'
				}).then(action => {
					if(action == "confirm") {
						this.removeMyShop(shop, index, type)
					}
					if(action == "cancel" && type == "item") {
						shop.checked = !shop.checked
					}
				});
			},
			changeStatus() {
				this.Ifshow = !this.Ifshow
			},
			changeStatus2() {
				this.Iftrue = !this.Iftrue
			},
			changeStuted(item) {
				let stuted = true
				this.Bus.$emit('changeStuted', stuted);
				this.Bus.$emit('shopItem', item)
			},
		},
	}
